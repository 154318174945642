// prevent safari from painting it's own select box style
select {
  -webkit-appearance:none;
}

.form-group {
  input:focus,
  select:focus {
    border-color: $black;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    border: #D0D0D0 solid 2px;
    box-shadow: none;
  }

  .input-group {
    //clip-path: inset(-10px $grid-gutter-width * 0.5 -10px $grid-gutter-width * 0.5);

    input,
    select {
      &:not(:last-child) {
        border-right-style: none;
      }

      &:not(:first-child) {
        border-left-style: none;
      }
    }

    .input-group-prepend .input-group-text {
      border-right-style: none;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .input-group-append .input-group-text {
      border-left-style: none;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }

  &.form-group-invalid {
    label {
      color: $danger;
    }

    .invalid-feedback {
      display: block;
    }

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      border-color: $danger;
    }
  }

  .input-group:focus-within {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

    .input-group-prepend .input-group-text,
    .input-group-append .input-group-text {
      border-color: $black;
    }

    input,
    select {
      box-shadow: none;
    }
  }

  &.form-group-invalid:focus-within {
    input,
    select {
      border-color: $danger;
      box-shadow: 0 2px 10px 0 rgba(204, 0, 112, 0.2);
    }

    .input-group {
      box-shadow: 0 2px 10px 0 rgba(204, 0, 112, 0.2);

      .input-group-prepend .input-group-text,
      .input-group-append .input-group-text {
        border-color: $danger;
      }

      input,
      select {
        box-shadow: none;
      }
    }
  }
}
