.stageboxes {
  margin-right: -30px;
  margin-left: -30px;
  margin-bottom: 4rem;
}

.stagebox {
  min-height: 8rem;
  padding: 1.25rem 0.25rem 1.25rem 0.75rem;

  .h2 {
    color: #FFFFFF;
  }

  .subtitle {
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.25rem;
    text-transform: uppercase;

    &::after {
      align-self: flex-end;
      content: '\e829';
      font-family: 'myclimate-icons';
      font-size: 2.5rem;
    }
  }
}

@media (min-width: 576px) {
  .stageboxes {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 992px) {
  .stagebox {
    min-height: 16rem;
    padding: 1.25rem 0.25rem 1.25rem  1.25rem;

    & + .stagebox {
      margin-left: 1.5rem;
    }
  }
}
