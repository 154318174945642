div.type-radio > label {
  margin-bottom: 1.5rem;
}

.form-check {
  line-height: 1.5rem;

  .form-check-label {
    margin-left: 1.2rem;
    font-weight: normal;
  }
}

.form-check:not(:last-child) {
  margin-bottom: 1rem;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0.5rem;
  }
}

input[type='radio'].form-check-input {
  position: absolute;
  border: 0;
  margin-top: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  &::before {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid $gray-3;
    background: none;
    border-radius: 50%;
    content: '';
  }

  &.is-invalid::before {
    border-color: $primary;
  }

  &:checked::before {
    border: 2px solid $secondary;
  }

  &:checked::after {
    position: absolute;
    top: 0.35rem;
    left: 0.35rem;
    width: 0.8rem;
    height: 0.8rem;
    background-color: $secondary;
    border-radius: 50%;
    content: '';
  }

  &:focus:checked::after {
    animation: scaleIn 0.1s 1 cubic-bezier(0.36, 0.11, 0.89, 0.32);
  }

  &[disabled]::before {
    border-color: $gray-4;
  }

  & ~ .form-check-label {
    padding-top: 0.2rem;
  }
}

input.form-check-input[type='radio'][disabled] ~ .form-check-label {
  color: $gray-3;
}

@keyframes scaleIn {
  from {
    transform: scale(0.2, 0.2);
  }

  to {
    transform: scale(1, 1);
  }
}
