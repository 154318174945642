.hero {
  display: flex;
  height: 25rem;
  min-height: 16rem;
  background-image: url('/assets/images/hero/myc_carbon_offset_headerbild.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    height: 29.75rem;
    background-image: url('/assets/images/hero/myc_carbon_offset_headerbild-sm.jpg');
  }

  @media (min-width: 992px) {
    height: 34rem;
  }

  @media (min-width: 1400px) {
    height: 37.5rem;
    background-attachment: fixed;
  }

  @media (min-width: 1600px) {
    background-image: url('/assets/images/hero/myc_carbon_offset_headerbild-xxl.jpg');
  }

  .hero-text {
    display: flex;
    height: 15rem;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 8.5rem;
  }
}
