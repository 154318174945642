#grid {
  height: 15rem;
  background: rgba(0,159,227,0.2);
  border-left: 1px solid #CC0070;
  border-right: 1px solid #CC0070;

  .column {
    .inner {
      background: #fcd4e5;
      height: 15rem;
      border: 1px solid #CC0070;
    }
  }
}
