
@import '02_colors';
@import '00_fonts';

// For bootstrap variables, which can be overridden in this file, refer to:
// https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss

// Color system

$gray-100: $gray-4;
$gray-200: $gray-4;
$gray-300: $gray-4;
$gray-400: $gray-3;
$gray-500: $gray-3;
$gray-600: $gray-2;
$gray-700: $gray-2;
$gray-800: $gray-1;
$gray-900: $gray-1;
$black: $black;

$grays: (
  '100': $gray-100,
  '200': $gray-200,
  '300': $gray-300,
  '400': $gray-400,
  '500': $gray-500,
  '600': $gray-600,
  '700': $gray-700,
  '800': $gray-800,
  '900': $gray-900,
);

$colors: (
  'gray': $gray-600,
  'gray-dark': $gray-800,
);

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'red': $red,
    'green': $green,
    'gray': $gray-3,
    'black': $black
  ),
  $theme-colors
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

//$enable-rounded: false;
$enable-validation-icons: false;

// Body
//
// Settings for the `<body>` element.

$body-color: $black;

// Links
//
// Style anchor elements.

$link-color: $secondary;
$link-decoration: none;
$link-hover-color: $black;
$link-hover-decoration: none;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1080px
);

// Components
//
// Define common padding and border radius sizes and more.

$custom-switch-width: 2.5rem;
$custom-control-indicator-size: 1.3rem;
$custom-control-indicator-border-width: 2px;

$border-width: 2px;
$border-color: $gray-3;
$border-radius: 0.03rem;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;

$box-shadow-sm: 0 3px 4px #0000001A;
$box-shadow-lg: 0 2px 10px #0000001A;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: $myc-font;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: 20px;
$line-height-base: 1.3;
$line-height-sm: 1.3;
$line-height-lg: 1.3;

$h1-font-size: 2rem;
$h2-font-size: 1.3rem;
$h3-font-size: 1.1rem;
$h4-font-size: 1rem;
$h5-font-size: 0.8rem;

$headings-font-weight: 700;
$headings-line-height: $line-height-base;
$headings-color: $black;

$text-muted: &black;
$small-font-size: 0.7rem;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 1.5rem;
$input-btn-font-size: 1rem;
$input-btn-font-size-lg: 1.4rem;
$input-btn-line-height: 1.1;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: 1rem;
$input-btn-line-height-sm: 1.1;

$input-btn-padding-y-lg: 1.1rem;
$input-btn-padding-x-lg: 3rem;
$input-btn-font-size-lg: 1rem;
$input-btn-line-height-lg: 1.1;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight: 700;
$btn-box-shadow: none;
$btn-disabled-opacity: 0.5;
$btn-active-box-shadow: none;

$btn-link-color: null;
$btn-link-hover-color: null;
$btn-link-disabled-color: null;

// Forms

$alert-bg-level: -12.5; // 12.5 * 8% $theme-color-interval == 100% white
$alert-border-level: 0;
$alert-color-level: 0;

$label-margin-bottom: 1rem;

$form-label-font-size: 1rem;
$form-label-font-weight: bold;

$input-color: $black;
$input-placeholder-color: $gray-600;

$input-padding-y: 0.6rem;
$input-padding-x: 0.6rem;

$form-check-input-border: 1px solid $gray-3;

$form-check-input-checked-color: $myc-dark-blue;
$form-check-input-checked-bg-color: $myc-dark-blue;
$form-check-input-checked-border-color: $myc-dark-blue;

$input-group-addon-bg: $white;

$form-select-padding-y: 0.35rem;
$form-select-padding-x: 1rem;

$form-select-focus-border-color: $black;

// Navs

$nav-link-padding-x: 0.8rem;
$nav-tabs-link-active-color: $black;
$nav-tabs-border-color: $gray-3;
$nav-tabs-link-active-border-color: $gray-3 $gray-3 #FFFFFF;

// Tooltip

$tooltip-bg: $gray-1;
$tooltip-opacity: 1;

$hr-border-width: 2px;

$modal-header-border-width: 0;
