.metaline {
  position: relative;
  min-width: 100%;
  height: 50px;
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  text-align: right;

  .container-md {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 50px;
    -ms-flex-direction: row;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -webkit-box-pack: end;
    -ms-flex-align: center;
    -ms-flex-pack: end;
  }

  .icn-only {
    display: inline-block;
    width: 50px;
    height: 35px;
    font-size: 0;
  }

  .nav-link {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
  }
}

.secondNav,
.language {
  .nav-link {
    display: block;
    font-weight: bold;

    &.active {
      color: $almost_black;
      background-color: white;
    }

    &:hover {
      color: $almost_black;
      background-color: white;
    }

    .icn-internet::before {
      color: $myc-dark-blue;
      font-size: 22px;
      font-weight: bold;
    }
  }
}

.language .language-title {
  padding: 0 10px;
  margin: 0;
  color: $myc-dark-blue;
  font-size: 0.8rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  column-gap: 3px;
  cursor: pointer;

    &:hover::after,
    &:hover,
    &:hover i:before,
    .active,
    .active i:before {
      color: $almost_black;
    }
}

.language .language-title::after {
  z-index: 1;
  border: none;
  color: $secondary;
  content: '\e829';
  font-family: 'myclimate-icons';
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 2.5rem;
  pointer-events: none;
  transform: rotate(90deg);
}

@media (min-width: 768px) {

  .dropdown.show .language-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column-reverse;
  }

  .language-menu li {
    padding: 0.3rem 0.7rem;
    line-height: 1.9rem;

    a {
      line-height: 1.5rem;
    }
  }

  .dropdown-menu.language-menu {
    min-width: 100%;
    border: none;
    border-radius: unset;
    margin: 0;
    position: absolute;

    ul {
      box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.1);
      padding: 10px 0;
    }
  }

  .language-menu .dropdown-item {
    color: $myc-dark-blue;
  }

  .language-menu .nav-link {
    padding: 0;
    font-size: 0.8rem;
    font-weight: bold;
    white-space: nowrap;
  }
}

@media (max-width: 767.98px) {
  .language{
    position: unset;
  }

  .language-title {
    .icn-internet::before {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .dropdown-menu{
    border: none;

    &.mobile-full-height {
      height: 100%;
      width: 100%;
    }

    .mobile--back-btn {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: $myc-dark-blue;
      font-size: 0.9rem;
      font-style: normal;
      line-height: normal;
      padding: 0 15px 0 8px;

      i:before {
        font-size: 1.25rem;
        transform: rotate(180deg);
        font-weight: bold;
      }
    }
  }

  .language  {

    .dropdown.show {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: calc(100vh - 75px);
      background-color: $white;

      .dropdown-menu{
        position: unset;
        background: $white;
        width: 100%;
      }

      nav.secondNav{
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 35px;
      }
    }

    .language-menu {
      border: none;
      margin-top: 38px;
      padding: 0;
    }

    .language-menu li {
      margin: 0 15px;
      padding: 15px 70px 15px 40px;
      display: flex;
      border-bottom: 1px solid $myc-dark-blue;
      position: relative;
    }

    .language-menu li:hover .nav-link{
      color: $black;
    }

    .language-menu li a{
      color: $myc-dark-blue;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
    }

    .language-menu {
        .nav-link.active {
          color: $myc-dark-blue;
        }

        a.active:before{
        content: '\e839';
        font-family: 'myclimate-icons';
        font-weight: 600;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-60%);
      }
    }
  }
}

.secondNav .nav-link {
  padding: 0 0.5rem;
  margin-right: 1rem;
}

.mainline {
  position: fixed;
  z-index: 300;
  right: 0;
  left: 0;
  display: flex;
}

main.page-content {
  padding-top: 20px;
  margin-top: 75px;
}

.tab-content {
  padding: 20px 0 0;
}

.stickyline {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px 0 5px 0;
  background-color: white;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  &::before {
    position: absolute;
    top: -15px;
    width: 100%;
    height: 15px;
    background-color: white;
    content: '';
  }
}

@media (max-width: 767.98px) {
  .mainline {
    width: 100%;
    padding-right: 0;
    padding-left: 0;

    &.mobile-full-height {
      height: 100%;
    }
  }

  .stickyline {
    width: 100%;
  }
}

.zettelIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0;

  .myclimate-logo {
    height: 45px;
    padding-right: 15px;
    padding-left: 5px;
  }

  #cart-icon-link .badge {
    position: absolute;
    top: 0;
    right: 9px;
    background-color: $primary;
    font-size: 0.6rem;
  }
}

.myclimate-projectlogo {
  padding: 0;
}

.zettelNav {
  &.nav {
    position: relative;
    flex-wrap: nowrap;
  }

  li {
    position: relative;
    align-items: center;
  }

  li > a {
    padding: 0 15px;
  }

  .close {
    width: 3.5rem;
    padding: 0 15px;
    color: $secondary;
    opacity: 1;
  }

  .close-menu {
    position: absolute;
    top: 0.3rem;
    right: 0;
    display: none;

    span::before {
      display: inline-block;
      width: 1em;
      content: '\e835';
      font-family: 'myclimate-icons';
      font-size: 2rem;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      line-height: 1em;
      text-align: center;
      text-decoration: inherit;
      text-transform: none;
    }
  }

  &.open {
    .nav-item.close-menu {
      display: block;
    }

    .nav-item:not(.close-menu) {
      visibility: hidden;
    }
  }
}

@media (max-width: 767.98px) {
  .zettelNav li > a {
    padding: 0 10px;

    &#search-toggler {
      display: none;
    }
  }
}

@media (max-width: 400px) {
  .zettelNav {
    li > a {
      padding-right: 0;
      padding-left: 5px;
    }
  }
}

#navbarMenu {
  > ul {
    padding-left: 0;
  }
  ul {
    list-style-type: none;
  }

  a.nav-link {
    display: block;
    padding: 1rem 0 1.5rem 0;
    font-size: 1.5rem;
    font-weight: normal;
  }

  ul ul a {
    font-size: 1rem;
    font-weight: bold;
  }
}

.zettelBox {
  margin-top: 10px;
  background-color: white;

  #meta-menu-xs {

    .language-menu {
      margin-right: 3rem;

      @media (max-width: 767.98px) {
        margin-top: 0.3rem;
        margin-right: 1rem;
      }

      li a.nav-link {
        margin-right: 1rem;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .mainline.mobile-full-height .zettelBox {
    overflow-y: scroll;
  }

  #navbarMenu > ul:first-child {
    margin-top: 1.5rem;
  }

  #navbarMenu a.nav-link {
    padding: 1rem 0 0.5rem 0;
  }
}

@media (max-width: 567.98px) {
  #navbarMenu {
    a.nav-link {
      padding: 0.3rem 0 0.4rem 0;
      font-size: 1.2rem;
    }

    .language-menu a {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}

#navbarLogin {
  label {
    color: $secondary;
    font-size: 0.8rem;

    &.login-title {
      font-weight: normal;
    }
  }

  form {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;

    .input-group {
      display: flex;
      margin-right: 1rem;
    }

    button {
      padding: 0;
      border: none;
      margin-top: 60px;
      margin-left: -25px;
      background: none;
      color: $secondary;
    }
  }

  .signup-reset {
    margin-bottom: 2rem;
    margin-left: 1rem;
    font-size: 0.8rem;

    p {
      margin-bottom: 1rem;
    }
  }

  .zettel-logout-link,
  .zettel-profile-link {
    padding-left: 1.2rem;
  }
}

#navbarSearch {
  form {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    margin-left: 1rem;

    .input-group {
      display: flex;
      margin-right: 1rem;
    }

    label {
      color: $secondary;
      font-size: 0.7rem;
    }

    input {
      padding-left: 0;
      border-bottom: 1px solid $black;
      margin-top: 0.5rem;
      font-size: 1rem;

      &:focus {
        border-bottom: 1px solid $secondary;
      }
    }

    button {
      padding: 0;
      margin-top: 50px;
      background: none;
      color: $secondary;
    }
  }
}

@media (min-width: 567.98px) and (max-width: 767.98px) {
  .dropdown-menu.show {
    transform: translateY(75px) !important;

    a.active:before {
    }
  }

  #navbarMenu a.nav-link {
    font-size: 1rem;
    font-weight: bold;
    padding: 0.3rem 0 0.4rem 0;
  }
}
