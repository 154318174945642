@import '../../styles/scss/bootstrap_customizations.scss';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../styles/scss/myclimate.scss';

@import 'raster.scss';
@import 'color_helper.scss';
@import 'hero_helper.scss';
@import 'syntax-highlighting.scss';

.styleguide-description {
  background-color: #FCFCFC;
  padding: 1em;
  border: 1px solid #DDDDDD;
}

.styleguide-example {
  position: relative;
  padding: 1rem;
  margin: 1rem 0 0 0;
  border: solid #f8f9fa;
  .show-html {
    position: absolute;
    top: 0.65rem;
    right: 0.65rem;
  }
}

.styleguide-code {
  position: relative;
}

.accordion .btn.btn-primary.collapsed {
  background-color: white;
  color: $primary;
}
