label {
  font-size: $form-label-font-size;
  font-weight: $form-label-font-weight;
  line-height: 1.2rem;

  abbr[title],
  abbr[data-original-title] {
    text-decoration: none;
  }
}

form {
  .form-group {
    padding-left: 0;
    margin-bottom: 1rem;

    .label-col {
      @include make-col-ready();
    }

    .wrapper-col {
      @include make-col-ready();

      &:not(.wrapper-col-full-width) {
        @include media-breakpoint-up(sm) {
          @include make-col(8);
        }

        @include media-breakpoint-up(md) {
          @include make-col(6);
        }

        @include media-breakpoint-up(xl) {
          @include make-col(4);
        }
      }
    }

    &.hidden {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    .form-group {
      margin-bottom: 1.5rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .form-group {
      margin-bottom: 2rem;
    }
  }
}

.form-text,
.invalid-feedback {
  font-size: $small-font-size;
  letter-spacing: 0.4px;
  line-height: 1rem;
}

@media (min-width: 768px) {
  .form-text,
  .invalid-feedback {
    font-size: 0.8rem;
    line-height: 1.1rem;
  }
}

@media (min-width: 992px) {
  .form-text,
  .invalid-feedback {
    font-size: 0.9rem;
    letter-spacing: 0.3px;
    line-height: 1.2rem;
  }
}
