.info-box {
  padding: 1.5rem 3rem 2.1rem 3rem;
  background: $gray-4;
  font-size: 0.9rem;
  line-height: 1.2rem;
}

@media (max-width: 575.98px) {
  .container.info-box-container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .info-box {
    padding: 2.5rem 4.75rem 3.5rem 4.75rem;
  }
}
