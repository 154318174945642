.nav-tabs {
  margin-bottom: 1rem;

  .nav-link {
    padding: 0.4rem 0.6rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-size: 0.9rem;
    font-weight: bold;
  }

  .nav-item:not(:first-child) {
    margin-left: 0.35rem;
  }

  .nav-item.tab-has-errors .nav-link {
    // border-top-color: rgba($danger, 0.1);
    // border-right-color: rgba($danger, 0.1);
    // border-left-color: rgba($danger, 0.1);
    color: $danger;

    &.active {
      // border-top-color: $danger;
      // border-right-color: $danger;
      // border-left-color: $danger;
      color: $danger;
    }
  }
}

.nav-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: space-between;

  .nav-button {
    @include media-breakpoint-down(md) {
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
    }
  }
}

@include media-breakpoint-up(lg) {
  .nav-tabs {
    padding-left: 0.6rem;

    .nav-link {
      padding: 0.4rem 0.8rem;
    }
  }
}

@include media-breakpoint-down(xs) {
  .nav-tabs {
    padding-left: 0;

    .nav-link {
      padding: 0.3rem 0.3rem;
    }
  }
}
