@import 'bootstrap_customizations';

h1,
.h1 {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 2.1rem;
  background: inherit;
  font-weight: bold;
  line-height: 2.1rem;
  text-shadow: none;
}

h2,
.h2 {
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
}

h3,
.h3 {
  margin-bottom: 1.3rem;
  letter-spacing: 0.2px;
  line-height: 1.3rem;
}

h4,
.h4 {
  margin-bottom: 1.1rem;
  letter-spacing: 0.3px;
  line-height: 1.1rem;
}

.text-normal {
  margin-bottom: 1.3rem;
  letter-spacing: 0.2px;
}

.text-small {
  margin-bottom: 1.2rem;
  font-size: 0.9rem;
  letter-spacing: 0.3px;
  line-height: 1.2rem;
}

.text-extra-small {
  font-size: $small-font-size;
  letter-spacing: 0.4px;
  line-height: 1rem;
}

.text-extra-small-narrow {
  margin-bottom: 0.9rem;
  font-size: $small-font-size;
  letter-spacing: 0.4px;
  line-height: 0.9rem;
}

@media (min-width: 768px) {
  h1,
  .h1 {
    margin-bottom: 2.6rem;
    font-size: 2.5rem;
    line-height: 2.6rem;
  }

  h2,
  .h2 {
    margin-bottom: 1.8rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  h3,
  .h3 {
    margin-bottom: 1.4rem;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  .text-normal {
    margin-bottom: 1.3rem;
  }

  .text-small {
    margin-bottom: 1.2rem;
  }

  .text-extra-small,
  .text-extra-small-narrow {
    margin-bottom: 1.1rem;
    font-size: 0.8rem;
    line-height: 1.1rem;
  }
}

@media (min-width: 1200px) {
  // h1,
  // .h1 {
  //   margin-bottom: 3.5rem;
  //   font-size: 3.25rem;
  //   line-height: 3.5rem;
  // }

  h2,
  .h2 {
    margin-bottom: 2.2rem;
    font-size: 2rem;
    line-height: 2.2rem;
  }

  h3,
  .h3 {
    margin-bottom: 1.6rem;
    font-size: 1.3rem;
    letter-spacing: 0;
    line-height: 1.6rem;
  }

  h4,
  .h4 {
    margin-bottom: 1.2rem;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.2rem;
  }

  .text-normal {
    margin-bottom: 1.4rem;
    font-size: 1.1rem;
    line-height: 1.4rem;
  }

  .text-small {
    margin-bottom: 1.3rem;
    font-size: 1rem;
    letter-spacing: 0.2px;
    line-height: 1.3rem;
  }

  .text-extra-small,
  .text-extra-small-narrow {
    margin-bottom: 1.2rem;
    font-size: 0.9rem;
    letter-spacing: 0.3px;
    line-height: 1.2rem;
  }
}
