@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins';

.portfolio-cards {
  @include make-row();
}

.portfolio-card {
  @mixin grid-stacked {
    grid-template-areas:
      'image'
      'description'
      'amount'
      'submit';
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto auto;
  }

  @mixin grid-big-image-left {
    grid-template-areas:
      'image description'
      'image amount'
      'image submit';
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr auto auto;
  }

  @mixin grid-big-image-top {
    grid-template-areas:
      'image image'
      'description description'
      'amount submit';
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
  }

  @include make-col-ready();

  display: flex;
  margin-top: 0; //map-get($spacers, 3);
  margin-bottom: map-get($spacers, 5);

  .portfolio-content {
    @include grid-stacked;

    display: grid;
    overflow: hidden;
    background-color: $gray-4;
    border-radius: $border-radius;

    &:hover {
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .portfolio-image {
      position: relative;
      grid-area: image;

      .carousel {
        .carousel-indicators li {
          width: 10px;
          height: 10px;
          margin-right: 10px;
          margin-left: 10px;
          border-radius: 50%;
        }

        .carousel-overlay {
          position: absolute;
          z-index: 1;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(128, 128, 128, 0) 50%, rgba(0, 0, 0, 0.5) 100%);
          pointer-events: none;
        }

        .control-icon::before {
          font-size: 3rem;
          text-shadow: 0 0 2px white;

          @include media-breakpoint-down(md) {
            font-size: 2rem;
          }

          @include media-breakpoint-down(sm) {
            font-size: 3rem;
          }

          @include media-breakpoint-down(xs) {
            font-size: 4rem;
          }
        }
      }
    }

    .portfolio-description {
      flex-grow: 2;
      padding: 20px 25px 20px 25px;
      grid-area: description;
      overflow-x: auto;

      a i {
        margin-left: -0.3rem;
      }
    }

    .portfolio-amount {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 25px 20px 25px;
      grid-area: amount;
    }

    .portfolio-submit {
      padding: 0 25px 25px 25px;
      grid-area: submit;
    }
  }

  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }

  @include media-breakpoint-up(md) {
    @include make-col(4);
  }

  @include media-breakpoint-up(lg) {
    .portfolio-content {
      .portfolio-description {
        padding: 18px 30px 30px 30px;
      }

      .portfolio-amount {
        padding: 0 30px 30px 30px;
      }

      .portfolio-submit {
        padding: 0 30px 38px 30px;
      }
    }
  }

  &.portfolio-card-big {
    @include make-col(12);

    .portfolio-content {
      @include grid-stacked;

      .portfolio-image {
        display: flex;
        align-items: center;

        a {
          height: 100%;
        }

        img {
          width: 100.2%;
          height: 100%;
          object-fit: cover;
          object-position: left;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      .portfolio-content {
        @include grid-big-image-top;
      }
    }

    @include media-breakpoint-up(md) {
      .portfolio-content {
        @include grid-big-image-left;
      }
    }
  }
}
