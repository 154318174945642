$myc-magenta: #E6007E;
$myc-dark-blue: #009FE3;
$myc-light-blue: #5BC5F2;
$myc-magenta-hover: #CC0070;
$myc-dark-blue-hover: #3389D6;
$myc-light-blue-hover: #45B5D8;

$primary: $myc-magenta;
$secondary: $myc-dark-blue;
$info: $myc-light-blue;
$danger: $myc-magenta;

$primary-hover: $myc-magenta-hover;
$secondary-hover: $myc-dark-blue-hover;
$info-hover: $myc-light-blue-hover;
$danger-hover: $myc-magenta-hover;

$red: #DC3545;
$green: #28A745;

$black: #222222;
$white: #FFFFFF;
$gray-1: #4A4A4A;
$gray-2: #B2B2B2;
$gray-3: #D0D0D0;
$gray-4: #F5F5F5;

$myc-magenta-shadow: #CC007033;

$almost_black: rgba(0, 0, 0, 0.9);

.bg-primary-hoverable {
  background-color: $primary;

  &:hover {
    background-color: $primary-hover;
  }
}

.bg-secondary-hoverable {
  background-color: $secondary;

  &:hover {
    background-color: $secondary-hover;
  }
}

.bg-info-hoverable {
  background-color: $info;

  &:hover {
    background-color: $info-hover;
  }
}

.bg-primary-hover {
  background-color: $primary-hover;
}

.bg-secondary-hover {
  background-color: $secondary-hover;
}

.bg-info-hover {
  background-color: $info-hover;
}
