/*!
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * "myclimate.org"
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * optimo.ch, 2018
 */

$myc-font: theinhardt, arial, sans-serif;

@font-face {
  font-family: 'Theinhardt';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/TheinhardtRegular-Regular.eot');
  src:
    url('/assets/fonts/TheinhardtRegular-Regular.eot') format('embedded-opentype'),
    url('/assets/fonts/TheinhardtRegular-Regular.svg') format('svg'),
    url('/assets/fonts/TheinhardtRegular-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Theinhardt';
  font-style: italic;
  font-weight: normal;
  src: url('/assets/fonts/TheinhardtRegular-Italic.eot');
  src:
    url('/assets/fonts/TheinhardtRegular-Italic.eot') format('embedded-opentype'),
    url('/assets/fonts/TheinhardtRegular-Italic.woff') format('woff'),
    url('/assets/fonts/TheinhardtRegular-Italic.svg') format('svg');
}

@font-face {
  font-family: 'Theinhardt';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/fonts/TheinhardtMedium-Regular.eot');
  src:
    url('/assets/fonts/TheinhardtMedium-Regular.eot') format('embedded-opentype'),
    url('/assets/fonts/TheinhardtMedium-Regular.woff') format('woff'),
    url('/assets/fonts/TheinhardtMedium-Regular.svg') format('svg');
}

@font-face {
  font-family: 'Theinhardt';
  font-style: italic;
  font-weight: bold;
  src: url('/assets/fonts/TheinhardtMedium-Italic.eot');
  src:
    url('/assets/fonts/TheinhardtMedium-Italic.eot') format('embedded-opentype'),
    url('/assets/fonts/TheinhardtMedium-Italic.woff') format('woff'),
    url('/assets/fonts/TheinhardtMedium-Italic.svg') format('svg');
}

@font-face {
  font-family: 'Theinhardt';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/TheinhardtLight-Regular.eot');
  src:
    url('/assets/fonts/TheinhardtLight-Regular.eot') format('embedded-opentype'),
    url('/assets/fonts/TheinhardtLight-Regular.woff') format('woff'),
    url('/assets/fonts/TheinhardtLight-Regular.svg') format('svg');
}

@font-face {
  font-family: 'Theinhardt';
  font-style: italic;
  font-weight: 300;
  src: url('/assets/fonts/TheinhardtLight-Italic.eot');
  src:
    url('/assets/fonts/TheinhardtLight-Italic.eot') format('embedded-opentype'),
    url('/assets/fonts/TheinhardtLight-Italic.woff') format('woff'),
    url('/assets/fonts/TheinhardtLight-Italic.svg') format('svg');
}
