.btn {
  text-transform: uppercase;

  &.no-upcase {
    text-transform: none;
  }

  &.no-box-shadow {
    box-shadow: none;

    &:focus,
    &:active {
      box-shadow: unset !important; /* stylelint-disable-line declaration-no-important */
    }
  }

  &.btn-long-text {
    padding-right: 1rem;
    padding-left: 1rem;
    text-align: start;
    text-transform: none;
  }

  &.btn-fixed-height {
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: center;

    &.btn-block {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@each $break-point-name, $break-point-value in $grid-breakpoints {
  @include media-breakpoint-up($break-point-name) {
    .btn-#{$break-point-name}-sm {
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
    }
    .btn-#{$break-point-name}-md {
      @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    }
    .btn-#{$break-point-name}-lg {
      @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
    }
  }
}
