input[type='text'],
input[type='email'],
input[type='password'] {
  height: 1.7rem;
  padding: 0;
  border-width: 0;
  border-bottom: 2px solid $gray-3;
  box-shadow: none;

  &:focus,
  &:active {
    border-color: $black;
    box-shadow: 0 8px 5px -5px rgba(0, 0, 0, 0.1);
  }

  &.is-invalid {
    border-color: $danger;

    &:focus,
    &:active {
      box-shadow: 0 8px 5px -5px rgba($danger, 0.2);
    }
  }
}

.form-group.string,
.form-group.email,
.form-group.password {
  .input-group {
    box-shadow: none !important; /* stylelint-disable-line declaration-no-important */

    .input-group-text {
      border: none;
    }

    .input-group-prepend span {
      padding-left: 0;
    }

    .input-group-append span {
      padding-right: 0;
    }
  }
}

textarea,
textarea.form-control {
  padding: 1.2rem;
  border: 2px solid $gray-3;
  border-radius: 2px;

  &:focus,
  &:active {
    border-color: $black;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }

  &.is-invalid:focus,
  &.is-invalid:active {
    box-shadow: 0 2px 10px 0 rgba(204, 0, 112, 0.2);
  }
}
