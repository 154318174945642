.form-check {
  input[type='checkbox'].form-check-input {
    border: 0;
    margin-top: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;

    &::before {
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      border: 2px solid $gray-3;
      border-radius: 2px;
      content: '';
    }

    &:checked::before {
      border: 2px solid $black;
    }

    &:checked::after {
      position: absolute;
      top: 0.02rem;
      left: 0.07rem;
      width: 0.8rem;
      height: 0.8rem;
      color: $secondary;
      content: '\e839';
      font-family: 'myclimate-icons';
      font-size: 1.35rem;
    }
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $secondary;
  background-color: $secondary;
}
