.link-collapse {
  display: block;
  margin-bottom: 1.5rem;
  font-family: $myc-font;
  font-weight: bold;
  line-height: 1.2rem;

  span {
    margin-top: 0.4rem;
  }

  &[aria-expanded=false] {
    i::before {
      content: '\e829';
    }
  }

  &[aria-expanded=true] {
    i::before {
      content: '\e82a';
    }
  }
}

.form-group + .form-group.link-collapse {
  margin-top: 2.1rem;
}
