.hero-header {
  margin-bottom: 2.75rem;
  color: #FFFFFF;
  font-size: 2.3rem;
  line-height: 2.3rem;
  font-weight: 300;
  text-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);
}

.hero-lead {
  color: #FFFFFF;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.5rem;
}

@media (min-width: 384px) {
  .hero-header {
    font-size: 2.75rem;
    line-height: 2.75rem;
  }
}

@media (min-width: 768px) {
  .hero-header {
    margin-bottom: 3.5rem;
    font-size: 3.5rem;
    line-height: 3.5rem;
  }

  .hero-lead {
    font-size: 1.6rem;
    line-height: 1;
  }
}

@media (min-width: 1200px) {
  .hero-header {
    margin-bottom: 4rem;
    font-size: 4.5rem;
    line-height: 4.5rem;
  }

  .hero-lead {
    font-size: 2rem;
  }
}
