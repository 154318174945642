@mixin select-caret {
  position: absolute;
  z-index: 1;
  padding-right: 0.5rem;
  color: $secondary;
  content: '\e82a';
  font-family: 'myclimate-icons';
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.5rem;
  pointer-events: none;
}

.form-group.select,
.form-group.country,
.form-group.time_zone {
  select::-ms-expand {
    display: none;
  }

  select {
    height: 2.5rem;
    padding-right: 2.5rem;
    -webkit-appearance: none;
    cursor: pointer;
  }

  .input-wrapper {
    position: relative;

    &::before {
      @include select-caret;

      right: 1rem;
    }
  }

  .input-group select + .input-group-append {
    position: relative;

    &::before {
      @include select-caret;

      left: -2.3rem;
      border-left-width: 0;
    }
  }
}
