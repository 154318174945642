@font-face {
  font-family: 'myclimate-icons';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/fonts/myclimate-icons.eot');
  src:
    url('/assets/fonts/myclimate-icons.eot') format('embedded-opentype'),
    url('/assets/fonts/myclimate-icons.woff2') format('woff2'),
    url('/assets/fonts/myclimate-icons.woff') format('woff'),
    url('/assets/fonts/myclimate-icons.ttf') format('truetype'),
    url('/assets/fonts/myclimate-icons.svg') format('svg');
}

[class^='icn-'],
[class*='icn-'] {
  line-height: 1;

  &::before {
    display: inline-block;
    width: 1em;
    font-family: 'myclimate-icons';
    font-size: 2rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
  }

  &.icn-center::before {
    line-height: 0.5em;
    vertical-align: -25%;
  }

  &.icn-big::before {
    font-size: 5rem;

    @include media-breakpoint-down(md) {
      font-size: 3.5rem;
    }
  }

  &.icn-sm::before {
    font-size: 1.5rem;
  }

  &.icn-xs::before {
    font-size: 1rem;
  }

  &.text-tooltip {
    color: rgba(34, 34, 34, 0.5);
    &:hover,
    &:active,
    &:focus {
      color: rgba(34, 34, 34, 1);
    }
  }
}

.icn-abfallmanagement::before {
  content: '\e800';
}

.icn-abmelden::before {
  content: '\e801';
}

.icn-auszaehlung::before {
  content: '\e802';
}

.icn-anmelden::before {
  content: '\e803';
}

.icn-auto::before {
  content: '\e804';
}

.icn-bildungsprojekte::before {
  content: '\e805';
}

.icn-biogas::before {
  content: '\e806';
}

.icn-biomasse::before {
  content: '\e807';
}

.icn-breadcrumbs::before {
  content: '\e808';
}

.icn-download::before {
  content: '\e809';
}

.icn-druckerei::before {
  content: '\e80a';
}

.icn-drucksachen::before {
  content: '\e80b';
}

.icn-effizienter-kocher::before {
  content: '\e80c';
}

.icn-energie::before {
  content: '\e80d';
}

.icn-energieeffizienz::before {
  content: '\e80e';
}

.icn-events::before {
  content: '\e80f';
}

.icn-externer-link::before {
  content: '\e810';
}

.icn-facebook-box::before {
  content: '\e811';
}

.icn-stern-1::before {
  content: '\e812';
}

.icn-facebook::before {
  content: '\e813';
}

.icn-flug::before {
  content: '\e814';
}

.icn-hamburger-navi::before {
  content: '\e815';
}

.icn-haushalt::before {
  content: '\e816';
}

.icn-innovation::before {
  content: '\e817';
}

.icn-instagram-box::before {
  content: '\e818';
}

.icn-instagram::before {
  content: '\e819';
}

.icn-internet::before {
  content: '\e81a';
}

.icn-klimaschutz::before {
  content: '\e81b';
}

.icn-kreuzfahrt::before {
  content: '\e81c';
}

.icn-linkedin-box::before {
  content: '\e81d';
}

.icn-linkedin::before {
  content: '\e81e';
}

.icn-lupe::before {
  content: '\e81f';
}

.icn-mail-teilen::before {
  content: '\e820';
}

.icn-mail::before {
  content: '\e821';
}

.icn-meine-projekte::before {
  content: '\e822';
}

.icn-news::before {
  content: '\e823';
}

.icn-pfeil-links::before {
  content: '\e824';
}

.icn-pfeil-oben::before {
  content: '\e825';
}

.icn-firma::before {
  content: '\e826';
}

.icn-pfeil-rechts::before {
  content: '\e827';
}

.icn-pfeil-s-oben::before {
  content: '\e828';
}

.icn-pfeil-s-rechts::before {
  content: '\e829';
}

.icn-pfeil-s-unten::before {
  content: '\e82a';
}

.icn-pfeil-unten::before {
  content: '\e82b';
}

.icn-planung::before {
  content: '\e82c';
}

.icn-plus::before {
  content: '\e82d';
}

.icn-preis-1::before {
  content: '\e82e';
}

.icn-preis-2::before {
  content: '\e82f';
}

.icn-preis-3::before {
  content: '\e830';
}

.icn-preis-leer::before {
  content: '\e831';
}

.icn-preis-plakat::before {
  content: '\e832';
}

.icn-publikums-preis::before {
  content: '\e833';
}

.icn-rechner::before {
  content: '\e834';
}

.icn-schliessen::before {
  content: '\e835';
}

.icn-sensibilisierung::before {
  content: '\e836';
}

.icn-solar::before {
  content: '\e837';
}

.icn-spenden::before {
  content: '\e838';
}

.icn-check::before {
  content: '\e839';
}

.icn-stern-2::before {
  content: '\e83a';
}

.icn-stern-3::before {
  content: '\e83b';
}

.icn-stern-leer::before {
  content: '\e83c';
}

.icn-teilen::before {
  content: '\e83d';
}

.icn-to-do::before {
  content: '\e83e';
}

.icn-twitter-box::before {
  content: '\e83f';
}

.icn-twitter::before {
  content: '\e840';
}

.icn-wald::before {
  content: '\e841';
}

.icn-warenkorb::before {
  content: '\e842';
}

.icn-wasser-sparen::before {
  content: '\e843';
}

.icn-wasserkraft::before {
  content: '\e844';
}

.icn-whatsapp-teilen::before {
  content: '\e845';
}

.icn-wind::before {
  content: '\e846';
}

.icn-wolke::before {
  content: '\e847';
}

.icn-youtube-box::before {
  content: '\e848';
}

.icn-youtube::before {
  content: '\e849';
}

.icn-pfeil-s-links::before {
  content: '\e84a';
}

.icn-fussabdruck::before {
  content: '\e84b';
}

.icn-info-sign::before {
  content: '\e84c';
}

.icn-at-sign::before {
  content: '\e84d';
}

.icn-compensate-flight::before {
  content: '\e814';
}

.icn-compensate-car::before {
  content: '\e804';
}

.icn-compensate-cruise::before {
  content: '\e81c';
}

.icn-compensate-footprint::before {
  content: '\e84b';
}

.icn-compensate-my_project::before {
  content: '\e822';
}

.icn-compensate-household::before {
  content: '\e816';
}

.icn-compensate-company::before {
  content: '\e826';
}

.icn-compensate-label::before {
  content: '\e852';
}

.icn-compensate-event::before {
  content: '\e80f';
}

.icn-compensate-contribution::before {
  content: '\e838';
}

.icn-compensate-subscription::before {
  content: '\e853';
}
