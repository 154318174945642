.btn.btn-link {
  width: auto;
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  color: $secondary;
  text-decoration: none;
  text-transform: none;

  &:focus {
    box-shadow: none;
  }
}

.link-with-icon {
  i {
    vertical-align: -18%;
  }
}
