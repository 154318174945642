.querteaser {
  flex: 0 1 100%;
  margin-bottom: 1rem;
  padding-left: 15px;
  padding-right: 15px;

  p {
    margin-bottom: 0;
    height: 4rem;
    span.text {
      width: 100%;
      padding: 0.8rem 1.3rem;
      color: #FFFFFF;
      font-family: $myc-font;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.25rem;

      &::after {
        margin: 0 0 0 1rem;
        content: '\e829';
        font-family: 'myclimate-icons';
        font-size: 2rem;
      }
    }
  }
}

@media (min-width: 992px) {
  .querteaser {

    p {
      height: 8rem;

      span.text {
        padding: 1rem 1rem 1.2rem;
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
}
