.steps-bar {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 24px;

  .step-item {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    color: $info;
    font-size: 1rem;
    font-weight: bold;

    // connection line to left
    &::before {
      position: absolute;
      z-index: 2;
      top: 1em;
      left: calc(-50% + 1em);
      width: calc(100% - 2em);
      border-bottom: 2px solid $info;
      content: '';
    }

    .step-item-icon {
      position: relative;
      z-index: 5;
      display: flex;
      width: 2em;
      height: 2em;
      align-items: center;
      justify-content: center;
      border: 2px solid $info;
      margin-bottom: 6px;
      border-radius: 50%;

      &::after {
        position: absolute;
        z-index: 2;
        content: '\e839';
        font-family: 'myclimate-icons';
        font-size: 1.3em;
        font-style: normal;
      }
    }

    &:first-child::before {
      content: none;
    }

    &.active {
      color: $black;
      font-weight: bold;

      .step-item-icon {
        border-width: 3px;

        &::after {
          // width: 1.2rem;
          // height: 1.2rem;
          width: 2rem;
          height: 2rem;
          background-color: $info;
          border-radius: 50%;
          content: '';
        }
      }

      // uncompleted items:
      ~ .step-item {
        color: #CCCCCC;

        &::before {
          border-color: #CCCCCC;
        }

        .step-item-icon {
          border-color: #CCCCCC;

          &::after {
            content: none;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .step-item {
      font-size: 0.8rem;

      &.active .step-item-icon::after {
        // width: 1rem;
        // height: 1rem;
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }
}
