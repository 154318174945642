@mixin button-text-variant($color, $hover-color: darken($color, 10%)) {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  color: $color;
  font-weight: $font-weight-normal;
  text-decoration: none;
  text-transform: none;

  @include hover() {
    color: $hover-color;
    text-shadow: 0 0 1px $color;
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }
}

@each $color, $value in $theme-colors {
  .btn-text-#{$color} {
    @include button-text-variant($value);
  }
}
