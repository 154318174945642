.bg-primary-hover {
  background-color: $primary-hover;
}
.bg-secondary-hover {
  background-color: $secondary-hover;
}
.bg-info-hover {
  background-color: $info-hover;
}

.bg-gray-1 {
  background-color: $gray-1;
}
.bg-gray-2 {
  background-color: $gray-2;
}
.bg-gray-3 {
  background-color: $gray-3;
}
.bg-gray-4 {
  background-color: $gray-4;
}

.bg-euk-orange {
  background-color: #f5520b;
}

.bg-cwc-green {
  background-color: #6eaa28;
}

.bg-ekp-green {
  background-color: #18a14a;
}